import React, { useState } from 'react'
import ImageWebp from 'src/components/ImageWebp'
import SectionComponent from 'src/components/SectionComponent'
import FormWhatsapp, { FormDataExternalPayload } from 'src/components/UI/Forms/FormWhatsapp'
import useWidth from 'src/hooks/window/useWidth'
import BaseLayout from 'src/layouts/BaseLayout'
import { WIDTH_MD } from 'src/styles/breakpoints'
import pageContext from './pageContext.json'

import ErrorView from 'src/components/PayrollSimulator/PayrollSimulatorINSS/components/ErrorView'
import SuccessView from 'src/components/PayrollSimulator/PayrollSimulatorINSS/components/SuccessView'
import { API_WHATSAPP } from 'src/config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const SimulateOnWhatsapp = () => {
  const width = useWidth()
  const [ isError, setIsError ] = useState(false)
  const [ isSuccess, setIsSuccess ] = useState(false)
  const isFormClean = !isSuccess && !isError
  const [ sendDatalayerEvent ] = useDataLayer()
  const formData: FormDataExternalPayload = {
    templateId: 'simulacao_inss_wht',
    metadata: {
      categoria_conversa: 'GR - Geral',
      matricula: 'Nao se aplica',
      campanhaOrigem: 'wpp_lp',
    },
  }
  const onFormSubmit = () => {
    sendDatalayerEvent({
      section: 'm_dobra_01',
      element_action: 'submit',
      element_name: 'Continuar pelo Whatsapp',
      section_name: 'Para continuar, precisamos de algumas informações pessoais',
    })
  }
  return (
    <BaseLayout pageContext={pageContext}>
      <SectionComponent
        id='emprestimo-consignado-inss-whatsapp'
        SectionStyles='pt-3 pt-md-5 pb-5 pr-md-0'
        minHeight={{ sm: '395px', md: '638px', lg: '442px', xl: '442px' }}
      >
        {isFormClean && (
          <div className='col-12 col-md-6 pt-2 pt-lg-3 pt-xl-4'>
            <FormWhatsapp
              onSuccessHandler={() => setIsSuccess(true)}
              onErrorHandler={() => setIsError(true)}
              formTitle='Simule e contrate seu consignado INSS direto pelo WhatsApp'
              formData={formData}
              apiUrl={API_WHATSAPP}
              onSubmitHandler={onFormSubmit}
            />
          </div>
        )}
        {isSuccess && (
          <div className='col-12 col-md-6 pt-2 pt-lg-3 pt-xl-4'>
            <SuccessView title='Tudo certo com as suas informações!' subTitle='Em alguns minutos, entraremos em contato para continuar sua simulação empréstimo INSS pelo WhatsApp.' />
          </div>
        )}
        {isError && (
          <div className='col-12 pt-2 pt-lg-3 pt-xl-4'>
            <ErrorView title='Erro ao carregar a página' subTitle='Por favor, tente novamente em alguns minutos.' />
          </div>
        )}
        {width >= WIDTH_MD && !isError && (
          <div className='col-md-5 offset-md-1'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/whatsapp-inss/image.webp'
              altDescription='Homem negro, vestindo camisa de manga longa na cor amarela, sentado em um sofá cinza, olhando para o celular em sua mão direita e segurando uma xícara em sua mão esquerda.'
            />
          </div>
        )}
      </SectionComponent>
    </BaseLayout>
  )
}

export default SimulateOnWhatsapp
