import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

type BackgroundProps = {
  backgroundPosition?: string;
  backgroundSize?: string;
  backgroundRepeat?: string;
}

export const SectionContainer = styled.section<BackgroundProps>`
  display: flex;
  position: relative;
  align-items: initial;
  min-height: calc(100vh - 64px);
  padding: 40px 0;
  background-position: ${(props: BackgroundProps) => props.backgroundPosition ? props.backgroundPosition : 'center center'};
  background-size: ${(props: BackgroundProps) => props.backgroundSize ? props.backgroundSize : 'cover'};
  background-repeat: ${(props: BackgroundProps) => props.backgroundRepeat ? props.backgroundRepeat : 'no-repeat'};

  @media ${device.tablet} {
    align-items: center;
    padding-top: 48px;
    padding-bottom: 48px;
  }
  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
    justify-content: space-between;
  }

  .row {

    justify-content: center;
    
    @media ${device.tablet} {
      align-items: center;
      justify-content: space-between;
    }
  }
`
