import React, { useEffect, useState } from 'react'

import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD, WIDTH_LG, WIDTH_XL, WIDTH_XXL } from 'src/utils/breakpoints'

import { SectionProps } from './types'

import { SectionContainer } from './style'

const SectionComponent = ({
  id, SectionStyles, ContainerStyles, RowStyles, pageDataImages, bgMobile, bgTablet,
  bgDesktopLG, bgDesktopXL, bgDesktopXXL, backgroundPosition, backgroundSize, backgroundRepeat, children, minHeight, bgColor, role, ariaLabel,
}: SectionProps) => {
  const data = pageDataImages

  const windowWidth = useWidth(300)
  const [ isBackground, setIsBackground ] = useState<string | undefined>('')
  const [ numberHeight, setNumberHeight ] = useState<string | number>()

  const minHeightSection = {
    ...minHeight,
  }

  const getBackgroundUrl = (bg?: string) => {
    if (data && typeof bg !== 'undefined') {
      return `url(${bg !== '' && data[bg].fluid.src})`
    }

    if (bg !== '') {
      return `url(${bg})`
    }

    return ''
  }

  useEffect(() => {
    if (windowWidth < WIDTH_MD) {
      setIsBackground(bgMobile)
      setNumberHeight(minHeightSection.sm)
    } else if (windowWidth >= WIDTH_MD && windowWidth < WIDTH_LG) {
      setIsBackground(bgTablet)
      setNumberHeight(minHeightSection.md)
    } else if (windowWidth >= WIDTH_LG && windowWidth < WIDTH_XL) {
      setIsBackground(bgDesktopLG)
      setNumberHeight(minHeightSection.lg)
    } else if (windowWidth >= WIDTH_XL && windowWidth <= WIDTH_XXL) {
      setIsBackground(bgDesktopXL)
      setNumberHeight(minHeightSection.xl)
    } else if (windowWidth > WIDTH_XXL) {
      setIsBackground(bgDesktopXXL)
      setNumberHeight(minHeightSection.xxl)
    }
  }, [ isBackground, windowWidth ])

  return (
    <SectionContainer
      id={id}
      backgroundPosition={backgroundPosition}
      backgroundSize={backgroundSize}
      backgroundRepeat={backgroundRepeat}
      className={SectionStyles}
      role={role}
      aria-label={ariaLabel}
      style={{
          backgroundImage: getBackgroundUrl(isBackground),
          minHeight: `${numberHeight}`,
          backgroundColor: `${bgColor}`,
      }}
    >
      <div className={`container ${ContainerStyles}`}>
        <div className={`row ${RowStyles}`}>
          {children}
        </div>
      </div>
    </SectionContainer>
  )
}

SectionComponent.defaultProps = {
  SectionStyles: '',
  ContainerStyles: '',
  RowStyles: '',
  bgMobile: '',
  bgTablet: '',
  bgDesktopLG: '',
  bgDesktopXL: '',
  bgDesktopXXL: '',
}

export default SectionComponent
